export const downloadIcon = (<svg width="100%" height="100%" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2_399)">
        <path d="M23.0001 5V25.586L14.0001 16.586L12.5861 18L24.0001 29.414L35.4141 18L34.0001 16.586L25.0001 25.586V5H23.0001Z" fill="white" />
        <path d="M5 31V40C5 41.654 6.346 43 8 43H40C41.654 43 43 41.654 43 40V31H41V40C41 40.552 40.552 41 40 41H8C7.448 41 7 40.552 7 40V31H5Z" fill="white" />
    </g>
    <defs>
        <clipPath id="clip0_2_399">
            <rect width="48" height="48" fill="white" />
        </clipPath>
    </defs>
</svg>
)


export const menuIcon = (<svg width="100%" height="100%" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path d="M6 9h36v4.5H6zm0 12.75h36v4.5H6zM6 34.5h36V39H6z" /></svg>)